import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './preview.scss'

const RecipePreview = ({ title, image, tagline, link }) => (
  <Link to={link} className="recipe-preview">
    {image ? (
      <Img
        className="recipe-preview__image"
        fluid={image.childImageSharp.fluid}
      />
    ) : (
      <span className="recipe-preview__image-placeholder" />
    )}
    <h3 className="recipe-preview__title">{title}</h3>
    <p className="recipe-preview__tagline">{tagline}</p>
  </Link>
)

export default RecipePreview
