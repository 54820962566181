import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import RecipePreview from '../Preview'
import './collection.scss'

const Collection = ({ title, items }) => (
  <div className="recipe-collection">
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <h1 className="recipe-collection__title layout-wrapper">{title}</h1>
    <ol className="recipe-collection__recipes layout-wrapper">
      {items.map(({ node: recipe }) => (
        <li
          key={`recipe-preview-${recipe.fields.slug}`}
          className="recipe-collection__recipe"
        >
          <RecipePreview
            title={recipe.frontmatter.title}
            image={recipe.frontmatter.image}
            link={'/recipe' + recipe.fields.slug}
            tagline={recipe.frontmatter.tagline}
          />
        </li>
      ))}
    </ol>
  </div>
)

export default Collection
